import { Fragment, useMemo } from "react";
import { RiCheckLine, RiFlag2Line, RiProhibitedLine } from "react-icons/ri";
import {
  Card,
  CardProps,
  chakra,
  Flex,
  Grid,
  HStack,
  Spacer,
  Spinner,
  Text,
  TextProps,
  useColorModeValue,
} from "@chakra-ui/react";

import { FeatureSourceType } from "@bucketco/shared/featureAPI";
import { reduce } from "@bucketco/shared/filter";
import { FlagRule, maxRolloutThreshold } from "@bucketco/shared/flagAPI";
import { getFraction } from "@bucketco/shared/utils/getFraction";

import { AdjustableArrow } from "@/common/components/AdjustableArrow";
import { FilterItem } from "@/common/components/filters/FilterItem";
import { SegmentFilterItem } from "@/common/components/filters/SegmentFilterItem";
import InfoIconTooltip from "@/common/components/InfoIconTooltip";
import TimestampCell from "@/common/components/TimestampCell";
import { useFeature } from "@/common/hooks/useFeatureFlags";
import { formatPercentage } from "@/common/utils/numberFormatting";
import pluralize from "@/common/utils/pluralize";
import { EnvironmentDisplayName } from "@/environment/components/EnvironmentDisplayName";
import { useFeatureRolloutStatusData } from "@/feature/data/useFeatureRolloutStatusData";

const CheckIcon = chakra(RiCheckLine);
const FlagIcon = chakra(RiFlag2Line);

export const RulesSummary = ({
  rules,
  ...rest
}: TextProps & { rules?: FlagRule[] | null }) => {
  const { isEnabled: expandRule } = useFeature(
    "expanded-flags-in-features-table",
  );

  const singleSimpleRule = useMemo(() => {
    const rule =
      rules?.length === 1 && rules[0].filter.type !== "group" ? rules[0] : null;
    if (rule) {
      return { ...rule, filter: reduce(rule.filter) };
    }
  }, [rules]);

  if (!rules?.length) {
    return (
      <HStack color="dimmed" spacing={1.5}>
        <RiProhibitedLine />
        <Text as="span" {...rest}>
          No one
        </Text>
      </HStack>
    );
  }

  if (!expandRule && singleSimpleRule?.filter.type === "segment") {
    return (
      <Text as="span" {...rest}>
        {singleSimpleRule.partialRolloutThreshold !== maxRolloutThreshold && (
          <>
            <Text as="span">
              {formatPercentage(
                getFraction(
                  singleSimpleRule.partialRolloutThreshold,
                  maxRolloutThreshold,
                ),
              )}
            </Text>{" "}
            <Text as="span" color="dimmed">
              of
            </Text>{" "}
          </>
        )}
        <SegmentFilterItem filter={singleSimpleRule.filter} compact showIcon />
      </Text>
    );
  } else if (expandRule && singleSimpleRule) {
    return (
      <Text as="span" {...rest}>
        {singleSimpleRule.partialRolloutThreshold !== maxRolloutThreshold && (
          <>
            <Text as="span">
              {formatPercentage(
                getFraction(
                  singleSimpleRule.partialRolloutThreshold,
                  maxRolloutThreshold,
                ),
              )}
            </Text>{" "}
            <Text as="span" color="dimmed">
              of
            </Text>{" "}
          </>
        )}
        <FilterItem
          filter={singleSimpleRule.filter}
          compact
          showIcon
          showPrefix
        />
      </Text>
    );
  }

  return (
    <Text
      alignItems="center"
      as="span"
      display="inline-flex"
      gap={1.5}
      {...rest}
    >
      <FlagIcon color="dimmed" />
      <Text as="span">
        {rules.length} {pluralize("rule", rules.length)}
      </Text>
    </Text>
  );
};

type RolloutStatusProps = CardProps & {
  featureId?: string;
  featureSource?: FeatureSourceType;
};

export const RolloutStatus = ({
  featureId,
  featureSource = "event",
  ...rest
}: RolloutStatusProps) => {
  const { data, isLoading, isRefetching } =
    useFeatureRolloutStatusData(featureId);

  const statuses = useMemo(() => (data ? [...data].reverse() : []), [data]);

  const arrowColor = useColorModeValue("gray.400", "gray.600");

  if (isLoading) {
    return (
      <Card
        alignItems="center"
        h="3xs"
        justifyContent="center"
        variant="filled"
        {...rest}
      >
        <Spinner color="dimmed" size="sm" />
      </Card>
    );
  }

  if (!statuses.length) {
    return null;
  }

  return (
    <Card
      fontSize="sm"
      maxW="100%"
      minH="3xs"
      overflowX="auto"
      p={6}
      position="relative"
      variant="filled"
      {...rest}
    >
      {isRefetching && (
        <Spinner
          color="dimmed"
          position="absolute"
          right={2}
          size="xs"
          top={2}
        />
      )}
      <Grid
        alignItems="flex-start"
        gap={4}
        gridAutoFlow="column"
        gridTemplateColumns={`auto repeat(${statuses.length}, 1fr)`}
        gridTemplateRows="repeat(5, 1fr)"
        minW="2xl"
        w="fit-content"
      >
        <Text color="dimmed" mr={10}>
          Environment
        </Text>
        <Text color="dimmed" mr={10}>
          Targeting
        </Text>
        <HStack mr={10} spacing={1}>
          <Text color="dimmed">Latest check</Text>
          <InfoIconTooltip text="Company checked if targeting rules matched and feature is enabled." />
        </HStack>
        <HStack mr={10} spacing={1}>
          <Text color="dimmed">Latest usage</Text>
          <InfoIconTooltip
            text={
              <Text as="span">
                {featureSource === "event"
                  ? "Event(s) last seen matching the usage criteria."
                  : "Company attribute(s) last changed to match the usage criteria."}
              </Text>
            }
          />
        </HStack>
        <HStack mr={10} spacing={1}>
          <Text color="dimmed">Latest feedback</Text>
          <InfoIconTooltip text="Does not include feedback entered manually." />
        </HStack>
        {statuses.map(
          (
            {
              environment,
              targetingRules,
              latestCheck,
              latestFeedback,
              latestUsage,
            },
            index,
          ) => (
            <Fragment key={environment.id}>
              <Flex align="center" justify="space-between" ml={-1}>
                <EnvironmentDisplayName
                  environment={environment}
                  fontSize="md"
                  mr={index < statuses.length - 1 ? 4 : 0}
                  link
                />
                <Spacer />
                {index < statuses.length - 1 && (
                  <AdjustableArrow color={arrowColor} length={24} />
                )}
                <Spacer />
              </Flex>
              <RulesSummary rules={targetingRules} />
              {latestCheck ? (
                <HStack>
                  <CheckIcon color="dimmed" />
                  <TimestampCell value={latestCheck} autoUpdate />
                </HStack>
              ) : (
                <Text color="dimmed">Not yet</Text>
              )}
              {latestUsage ? (
                <HStack>
                  <CheckIcon color="dimmed" />
                  <TimestampCell value={latestUsage} autoUpdate />
                </HStack>
              ) : (
                <Text color="dimmed">Not yet</Text>
              )}
              {latestFeedback ? (
                <HStack>
                  <CheckIcon color="dimmed" />
                  <TimestampCell value={latestFeedback} autoUpdate />
                </HStack>
              ) : (
                <Text color="dimmed">Not yet</Text>
              )}
            </Fragment>
          ),
        )}
      </Grid>
    </Card>
  );
};
