import { useEffect, useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { IconType } from "react-icons";
import { Badge, Flex, TagLabel, TagLeftIcon, Text } from "@chakra-ui/react";

import {
  AttributeField,
  AttributeFilterOperator,
  defaultTypeValues,
  listOperators,
  operatorDisplayName,
  operatorsWithoutValue,
  relevantOperators,
  systemAttributeFields,
  TypeByOperator,
} from "@bucketco/shared/attributeFilter";
import {
  AdjustAttributeFieldsCallback,
  CompanyAttributeFilter,
  UIFilterType,
} from "@bucketco/shared/filter";

import { TextWordBreak } from "@/common/components/TextWordBreak";
import { useErrorToast } from "@/common/hooks/useErrorToast";
import { useFormFieldDiff } from "@/common/hooks/useFormFieldDiff";
import { useFormFieldUpdate } from "@/common/hooks/useFormFieldUpdate";
import {
  AttributeSourceEntityType,
  useAttributeNames,
} from "../../data/useAttributeNames";
import { ManagedFormControl } from "../Form/ManagedFormControl";
import RuleAttribute from "../Rule/RuleAttribute";
import RuleOperator from "../Rule/RuleOperator";
import RuleValue, {
  FieldType,
  getFieldTypeFromAttributeType,
} from "../Rule/RuleValue";

import FilterValue from "./FilterValue";

type AttributeFilterItemProps = {
  filter: Omit<CompanyAttributeFilter, "type">;
  prefix?: string;
  icon?: IconType;
  compact?: boolean;
};

export function AttributeFilterItem({
  filter,
  icon,
  compact = false,
  prefix,
}: AttributeFilterItemProps) {
  const systemAttribute = systemAttributeFields.find(
    (field) => field.key === filter.field,
  );

  const label = systemAttribute?.label ?? filter.field;
  const size = compact ? "xs" : "sm";

  return (
    <>
      {icon && <TagLeftIcon as={icon} h="auto" ml="-1px" size={16} w="auto" />}
      <TagLabel whiteSpace="normal">
        <Flex columnGap={compact ? 1 : 2} wrap="wrap">
          {prefix && (
            <Badge color="dimmed" colorScheme="blackAlpha" size={size}>
              {compact ? prefix[0] : prefix}
            </Badge>
          )}
          <TextWordBreak as="span" fontSize={size}>
            {label}
          </TextWordBreak>
          <Text as="span" color="dimmed" fontSize={size}>
            {operatorDisplayName[filter.operator]}
          </Text>
          <FilterValue
            operator={filter.operator}
            size={size}
            type={systemAttribute?.type}
            value={filter.values}
          />
        </Flex>
      </TagLabel>
    </>
  );
}

const getValueType = (
  attribute: AttributeField | undefined,
  operator: CompanyAttributeFilter["operator"],
): FieldType => {
  if (operatorsWithoutValue.includes(operator)) return "none";
  if (listOperators.includes(operator)) return "list";

  return getFieldTypeFromAttributeType(
    attribute?.type ?? TypeByOperator[operator],
  );
};

type AttributeFilterFieldsProps = {
  type: Extract<
    UIFilterType,
    "companyAttribute" | "userAttribute" | "otherContext"
  >;
  adjustAttributeFields?: AdjustAttributeFieldsCallback;
};

const attributeSourceEntityTypeMap: Record<
  Extract<UIFilterType, "companyAttribute" | "userAttribute" | "otherContext">,
  AttributeSourceEntityType
> = {
  companyAttribute: "company",
  userAttribute: "flag-context-user",
  otherContext: "flag-context-other",
};

export function AttributeFilterFields({
  type,
  adjustAttributeFields,
}: AttributeFilterFieldsProps) {
  const {
    attributes: all,
    isLoading: isAttributesLoading,
    isError: isAttributesError,
  } = useAttributeNames(attributeSourceEntityTypeMap[type]);

  const errorToast = useErrorToast();

  useEffect(() => {
    if (isAttributesError) {
      errorToast({ description: "Failed to load attributes", duration: 4000 });
    }
  }, [isAttributesError, errorToast]);

  const attributes = useMemo(
    () => adjustAttributeFields?.(type, all) || all,
    [adjustAttributeFields, all, type],
  );

  const form = useFormContext<CompanyAttributeFilter>();

  const attributeKey = useWatch({ name: "field" });
  const operator = useWatch<{ operator: AttributeFilterOperator }>({
    name: "operator",
  });
  const attribute = attributes.find((field) => field.key === attributeKey);

  const operators = relevantOperators([
    "any",
    "date",
    "text",
    "number",
    "list",
    "boolean",
  ]);
  const valueType = getValueType(attribute, operator);

  // The field changed. Reset the operator to default if the field type does not support the current operator.
  useFormFieldUpdate(form, "field", (newValue) => {
    if (form.getValues("type") !== type) {
      return;
    }

    const currentOperator = form.getValues("operator");
    const attribute = attributes.find((field) => field.key === newValue);
    const operators = relevantOperators([
      "any",
      "date",
      "text",
      "number",
      "list",
      "boolean",
    ]);

    const attributeType = attribute?.type;
    if (attributeType && attributeType in operators) {
      if (!operators[attributeType].includes(currentOperator)) {
        form.setValue("operator", operators[attributeType][0]);
      }
    }
  });

  // The operator changed. Reset value to default if the value type also changed.
  useFormFieldDiff(form, "operator", ({ oldValue, newValue }) => {
    if (form.getValues("type") !== type) return;
    const valueType = TypeByOperator[newValue];
    const previousValueType = oldValue && TypeByOperator[oldValue];

    if (valueType !== previousValueType) {
      form.setValue("values", defaultTypeValues[valueType], {
        shouldDirty: true,
      });
    } else {
      // Validate value field, since the new operator might not work
      // form the existing value
      form.trigger("values");
    }
  });

  return (
    <>
      <ManagedFormControl
        name="field"
        render={({ field }) => (
          <RuleAttribute
            {...field}
            attributes={attributes}
            isLoading={isAttributesLoading}
          />
        )}
      />

      <ManagedFormControl
        name="operator"
        render={({ field }) => (
          <RuleOperator {...field} operators={operators} />
        )}
      />

      <ManagedFormControl
        key={`${attributeKey}.${operator}`}
        name={listOperators.includes(operator) ? "values" : "values[0]"}
        render={({ field }) => {
          return (
            <RuleValue
              {...field}
              attributeKey={attributeKey}
              entityType={attributeSourceEntityTypeMap[type]}
              fieldType={valueType}
            />
          );
        }}
      />
    </>
  );
}
