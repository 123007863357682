import { useState } from "react";
import { RiCodeSSlashLine } from "react-icons/ri";
import {
  Button,
  ButtonGroup,
  Card,
  chakra,
  Flex,
  Heading,
  Link,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

import { FeatureCodeExample } from "@/feature/components/FeatureCodeExample";
import { RolloutStatus } from "@/feature/components/RolloutStatus";
import { useFeatureData } from "@/feature/data/useFeatureData";
import { FlagKeyCopyDisplay } from "@/flags/components/FlagKeyCopyDisplay";
import { useFlagData } from "@/flags/data/useFlagData";

const CodeIcon = chakra(RiCodeSSlashLine);

type Props = {
  featureId?: string;
  onHide?: () => void;
};

export const SetupInstructions = ({ featureId, onHide }: Props) => {
  const [tab, setTab] = useState<"rollout" | "examples">("rollout");
  const { data: feature, isLoading: isFeatureLoading } =
    useFeatureData(featureId);
  const { data: flag, isLoading: isFlagLoading } = useFlagData(feature?.flagId);

  const accentColor = useColorModeValue("yellow.600", "orange.300");

  if (isFeatureLoading || isFlagLoading || !feature || !flag) return null;

  return (
    <Card
      borderLeftColor={accentColor}
      borderLeftWidth={3}
      direction="row"
      gap={8}
      p={6}
      position="relative"
    >
      <Button
        color="dimmed"
        position="absolute"
        right={2}
        top={2}
        variant="ghost"
        onClick={onHide}
      >
        Hide
      </Button>
      <Flex direction="column" flexShrink={0} gap={4} maxW="2xs" py={2}>
        <Heading
          alignItems="center"
          color={accentColor}
          display="flex"
          fontSize="lg"
          gap={2}
        >
          <CodeIcon size={20} />
          <Text as="span">Integrate with your app</Text>
        </Heading>
        <Text color="dimmed" fontSize="sm" maxW="sm" mt={2}>
          Bucket has SDKs for{" "}
          <Link
            href="https://docs.bucket.co/quickstart/react-sdk"
            target="_blank"
          >
            React
          </Link>
          ,{" "}
          <Link
            href="https://docs.bucket.co/quickstart/browser-sdk"
            target="_blank"
          >
            Browser
          </Link>
          , and{" "}
          <Link
            href="https://docs.bucket.co/quickstart/node.js-sdk"
            target="_blank"
          >
            Node.js
          </Link>{" "}
          to get you quickly up and running. We also have a{" "}
          <Link href="https://docs.bucket.co/api/http-api" target="_blank">
            HTTP API
          </Link>
          .
        </Text>
        <Text color="dimmed" fontSize="sm" maxW="sm" mt={2}>
          Use the feature key to implement targeting (feature flags)
          {feature?.source === "event" && " and usage tracking"}:
        </Text>
        <FlagKeyCopyDisplay flag={flag} />
      </Flex>
      <Flex direction="column" flexGrow={1} gap={4}>
        <ButtonGroup isAttached>
          <Button
            isActive={tab === "rollout"}
            variant="outline"
            onClick={() => setTab("rollout")}
          >
            Roll-out status
          </Button>
          <Button
            isActive={tab === "examples"}
            variant="outline"
            onClick={() => setTab("examples")}
          >
            Code examples
          </Button>
        </ButtonGroup>
        {tab === "rollout" && (
          <RolloutStatus featureId={featureId} featureSource={feature.source} />
        )}
        {tab === "examples" && (
          <FeatureCodeExample
            featureSource={feature.source}
            flagKey={flag.key}
            sharingPreface="Implement feature in Bucket"
            trackingEventBase="Feature Instructions"
          />
        )}
      </Flex>
    </Card>
  );
};
